<template>
  <div id="channels-container">
    <h2><i class="fad fa-fw fa-sitemap"></i> Traffic Channels</h2>
    <p class="lead">
      Compare your Traffic Channels with Google Analytics and Ads integration by your
      <router-link to="/kpis">Success Metrics</router-link>
      , entry points into the
      <router-link to="/journeys">Customer Journey</router-link>
      , relevant
      <router-link to="/keywords">Keywords</router-link>
      and corresponding
      <router-link to="/contents">Content</router-link>
      .
    </p>

    <ComingSoonBanner></ComingSoonBanner>

    <!--<p>Todo:</p>
    <ul>
      <li>Build Google Analytics and Google Ads integration</li>
      <li>Build Charts</li>
      <li>Connect to <router-link to="/journeys">Customer Journeys</router-link></li>
      <li>Connect to <router-link to="/keywords">Keyword Planner</router-link></li>
      <li>Connect to <router-link to="/contents">Content Library</router-link></li>
      <li>Connect to <router-link to="/kpis">KPI Tools</router-link></li>
    </ul>-->

  </div>
</template>

<script>
// @ is an alias to /src

import ComingSoonBanner from "@/components/ComingSoonBanner";

export default {
  name: 'Channels',
  components: {
    ComingSoonBanner
  }
}
</script>
